.main-container {
  width: 100%;
}

.page-header {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 40px;
  min-height: 40px;
  background: #0178d4;
  z-index: 1030;
  display: flex;
  justify-content: space-between;
  box-shadow: 6px 8px 8px -5px #aaa;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.ph-left {
  display: flex;
  align-items: center;
  width: 15rem;
}

.menu-toggle {
  color: #fff;
  padding-left: 1rem;
  cursor: pointer;
}

.logo-section {
  border-bottom: 1px solid #ddd;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
}

.ph-icons {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%;
}
.sidebar {
  position: fixed;
  margin-left: 0;
  top: 40px;
  background: #f3f2f1;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.06) 0,
    rgba(0, 0, 0, 0) 100%
  );
  width: 15rem;
  height: 100%;
  font-size: 0.8rem;
  box-shadow: inset -4px 0px 15px 3px rgb(0 0 0 / 8%);
  transition: 1s all;
  overflow: auto;
}

.sidenav {
  height: 100%;
  width: 0;
  background-color: #fdfdfd;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  border-left: solid 1px #ddd;
}

.mfyactive {
  width: 20% !important;
}
@media (max-width: 767.98px) {
  .mfyactive {
    width: 100% !important;
  }
}
