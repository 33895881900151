body {
  padding: 0;
  margin: 0;
  font-family: Segoe UI;
  font-size: 13px;
}
.main-container {
  width: 100%;
}
a,
a:hover,
.nav-link,
.nav-link:hover {
  text-decoration: none;
  color: #000;
}
.bi-circle::before {
  content: "\f28a";
  font-weight: 900 !important;
  font-size: 0.7rem !important;
  color: #000;
}
ul {
  padding: 0rem;
}
ul li {
  list-style: none;
}

/*----------------- Start Page Header Style -----------------*/
.page-header {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 40px;
  min-height: 40px;
  background: #0178d4;
  z-index: 1030;
  display: flex;
  justify-content: space-between;
  box-shadow: 6px 8px 8px -5px #aaa;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.ph-icons {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 100%;
}
.ph-list {
  display: inline-block;
  padding: 0 0.5rem;
}
.ph-item,
.ph-item:hover {
  color: #fff;
  text-decoration: none;
}
.ph-left {
  display: flex;
  align-items: center;
  width: 15rem;
}
.logo-section {
  padding-left: 1rem;
}
.menu-toggle {
  color: #fff;
  padding-left: 1rem;
  cursor: pointer;
  font-size: 18px;
}
.page-container {
  width: calc(100% - 15rem);
  margin: 40px 0 0 15rem;
}
.page-bar {
  display: flex;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  border-bottom: solid 1px #ddd;
}
.page-bar-left {
  font-size: 1.25rem;
}
.page-bar-right {
  font-size: 1rem;
}

/*----------------- !End Page Header Style -----------------*/

/*----------------- Start Page Inside Container Style -----------------*/

.page-inside-container {
  padding: 0rem 1.5rem;
}

/*----------------- !End Page Inside Container Style -----------------*/

/*----------------- Start Sidebar Style -----------------*/
.sidebar {
  position: fixed;
  margin-left: 0;
  top: 40px;
  background: #f3f2f1;
  /* background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.06) 0,
    rgba(0, 0, 0, 0) 100%
  ); */
  width: 15rem;
  height: 100%;
  font-size: 0.8rem;
  box-shadow: inset -4px 0px 15px 3px rgb(0 0 0 / 8%);
  transition: 1s all;
  overflow: auto;
}
.sidebar .nav-link {
  display: flex;
  white-space: nowrap;
}
.has-submenu .nav-link.active {
  background: #e3ebf2;
  color: #000001;
}
.sidebar .nave-item {
  position: relative;
}
.menu-icon {
  margin-right: 0.4rem;
  font-size: 1rem;
  width: 20px;
}
.menulevel-2 .menu-icon {
  font-size: 0.85rem;
}
.sidebar .nav-link span {
  display: inline-block;
  transition: 1s all;
  white-space: nowrap;
}
.menu-icon-right {
  font-size: 1rem;
  position: absolute;
  right: 1rem;
  transition: 1s all;
}
.menulevel-2 .nav-link {
  padding-left: 2rem;
}
.menulevel-2 .nav-link:hover {
  background: #e3ebf2;
}
.menulevel-2 .nav-link.active .menu-icon::before {
  color: #3490f7;
}
.submenu {
  transition: 1s all;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #bbb;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
/*----------------- !End Sidebar Style -----------------*/

/*----------------- Start Tabdrop Style -----------------*/
.nav-tabs,
.nav-pills {
  position: relative;
}

/* adjust as needed so the tab text displays */
.tabdrop {
  width: 30px;
  margin-top: 0.5rem;
}

/* hide any icons found in tabs not on first line */
.nav-tabs li li i {
  visibility: hidden;
}

.hide {
  display: none;
}
.open > .dropdown-menu {
  display: block;
}
.dropdown-menu {
  box-shadow: 5px 5px rgb(102 102 102 / 10%);
  border: 1px solid #eee;
  padding: 0rem;
  font-size: 0.8rem;
  top: 8px !important;
}
.dropdown-menu::before {
  position: absolute;
  top: -8px;
  right: 9px;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e0e0e0;
  border-left: 8px solid transparent;
  content: "";
}
.dropdown-menu:after {
  position: absolute;
  top: -7px;
  right: 10px;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

.dropdown-menu li:hover a {
  background-color: #f6f6f6;
  color: #555;
}
.tabdrop-section.nav-tabs {
  margin-bottom: 0px;
  border: 0;
}

.tabdrop-section.nav-tabs:after,
.tabdrop-section.nav:before {
  content: " ";
  border-bottom: 1px solid #dae2ea;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.tabdrop-section.nav-tabs .nav-link {
  border: 0;
}
.tabdrop-section .nav-item .nav-link {
  border-bottom: 4px solid transparent;
}
.tabdrop-section .nav-item:hover .nav-link {
  border-bottom: 4px solid #dae2ea;
}
.tabdrop-section.nav-tabs .nav-link.active {
  background: none;
  border: 0;
  border-bottom: 4px solid #0178d4;
}
.tabdrop-section .dropdown-menu .nav-item a.nav-link {
  border: 0;
}
/* .tabdrop-section li.nav-item{
  border: 4px solid transparent ;
  border-bottom: 4px solid transparent ;
} */
/* .tabdrop-section ul{
  border: 4px solid transparent;
  border-bottom: 4px solid #eee;
}
.tabdrop-section .nav-item:hover .nav-link{
  border-bottom: 4px solid #dae2ea !important;
}
.tabdrop-section .nav-tabs .nav-link.active{
  border-bottom: 4px solid #0178d4;
} */
.tabdrop-section .dropdown-menu {
  border: 1px solid #eee;
  border-radius: 0;
}
.tabdrop-section .dropdown-menu .nav-item:hover .nav-link {
  border: 0;
}
.tabdrop-responsive-icon {
  color: #a6a6a6;
  width: 20px;
  text-align: center;
}
.tabdrop-section .dropdown-toggle::after {
  content: unset;
}
.tabdrop-section .nav-link:focus,
.tabdrop-section .nav-link:hover {
  color: #000;
}
/*----------------- !End Tabdrop Style -----------------*/

/*----------------- Start Notification Modal Style -----------------*/
.Notification-modal {
  top: 40px;
  bottom: 0;
}
.Notification-modal .modal-dialog {
  position: absolute;
  margin: 0;
  right: -350px;
}
.Notification-modal .modal-content {
  width: 350px;
  margin: 0;
  height: calc(100vh - 40px);
  border: 0;
  border-radius: 0;
  box-shadow: 0 25.6px 57.6px 0 rgb(0 0 0 / 22%),
    0 4.8px 14.4px 0 rgb(0 0 0 / 18%);
}
.modal-backdrop,
.modal-backdrop.show {
  background-color: unset;
}
.Notification-modal.modal.fade .modal-dialog {
  transition: opacity 0.3s linear, right 0.5s ease-out;
  transform: translate3d(0%, 0, 0);
}
.Notification-modal.modal.show .modal-dialog {
  transform: none;
  right: 0;
}
/*----------------- !End Notification Modal Style -----------------*/

/*----------------- Start Minify Style -----------------*/

.minify .sidebar {
  width: 3rem;
  transition: opacity 0.3s linear, right 0.5s ease-out;
  transition: 1s all;
}

.minify .sidebar .node_name_area {
  display: none;
}
.minify .sidebar .icon_tree_area {
  padding: 5px;
  margin: 5px;
  height: 25px;
}
.minify .sidebar .node_line {
  height: 30px;
}

.minify .sidebar .icon_tree_area .icon-togle {
  width: 25px;
}
.icon-togle {
  height: 22px;
}
.node_line {
  margin-top: 5px;
}
.minify .sidebar .icon_tree_area {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0.5rem !important;
}
/* .minify .sidebar .icon-togle {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0.5rem !important;
} */

.minify .page-container {
  width: calc(100% - 3rem);
  margin-left: 3rem;
  transition: 1s all;
}
.minify .sidebar .nav-link span,
.minify .sidebar .menu-icon-right {
  visibility: hidden;
  transition: 0.1s all;
}
.minify .submenu {
  display: none;
  transition: 1s all;
}
/*----------------- !End Minify Style -----------------*/
.bell {
  display: block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #eee607;
  -webkit-animation: tada 1.5s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: tada 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
  cursor: pointer;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: scale3d(1, 1, 1) rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: scale3d(1.2, 1.5, 1.2) rotate(-24deg);
  }

  17% {
    transform: scale3d(1.4, 1.8, 1.4) rotate(22deg);
  }

  19% {
    transform: scale3d(1.5, 2, 1.5) rotate(-20deg);
  }

  21% {
    transform: scale3d(1.4, 1.8, 1.4) rotate(18deg);
  }

  23% {
    transform: scale3d(1.2, 1.5, 1.2) rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: scale3d(1, 1, 1) rotate(1deg);
  }

  43% {
    transform: scale3d(1, 1, 1) rotate(0);
  }

  100% {
    transform: scale3d(1, 1, 1) rotate(0);
  }
}
/*-----------*/
.datpickerWrapper {
  width: 100%;
  border: red;
}
.datpickerWrapper input {
  border: none !important;
  width: 100%;
  border-bottom: 1px solid grey !important;
  height: 30px;
}

.datpickerWrapper input:focus {
  background-color: #fffdf0;
  border: solid 2px rgb(0 148 211 / 40%) !important;
  padding: 2px 2px 3px 2px;
  line-height: 20px;
  width: 100%;
  border-radius: 6px !important;
  font-weight: normal;
  outline: none;
}
/*----------------- Start Responsive Layout Style -----------------*/

@media (min-width: 0px) and (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (max-width: 767.98px) {
  .sidebar {
    width: 100%;
    padding: 5px;
  }
  .page-container {
    width: 100%;
    margin-left: 0;
  }
  .minify .sidebar {
    width: 0%;
    margin-left: 0rem;
    z-index: 1031;
    padding: 0px;
  }

  .minify .active {
    width: 100%;
    padding: 5px;
  }

  .minify .deactive {
    width: 0% !important;
    padding: 0px;
  }

  .minify .sidebar .nav-link span,
  .minify .sidebar .menu-icon-right {
    visibility: visible;
  }
  .minify .page-container {
    width: 100%;
    margin-left: 0px;
    transition: 1s all;
  }
  .border-right {
    border: 0;
  }

  .logo-section {
    width: calc(100% - 50px);
    padding-left: 0;
  }
  .menu-toggle {
    text-align: center;
    padding-right: 0;
    color: #000;
  }

  .page-header {
    background: white;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

/*----------------- !End Responsive Layout Style -----------------*/
