.main {
  width: 100%;
  display: flex;
}
.login-wrapper {
  background-color: #e3e3e3;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  background: #fff;
  width: 100%;
  max-width: 500px;
  border-radius: 5px;

  /* margin-top:50px;*/
}

.logo-section-login {
  border-bottom: 1px solid #ddd;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-section {
  width: 100%;
  display: flex;
}
.login-left,
.login-right {
  width: 100%;
  padding: 1rem;
}

.login-container {
  width: 100%;
  padding: 1rem;
}

.login-left {
  background-color: #dedede;
  /* background-image: url('../images/loginleftbg.svg'); */
  background-attachment: fixed;
  background-size: cover;
}
.error_message {
  color: red;
}

.one_line_data {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 0px 6px;
  margin-top: 4px;
  margin-right: auto;
  margin-left: auto;
}

.login-form {
  padding: 6px;
  position: relative;
}
.login-cred-box {
  display: flex;
  height: 50px;
  padding: 6px;
  background-color: #f6f5f9;
  border: 1px solid #eee;
  gap: 10px;
  align-items: center;
  margin: 10px;
}

.login-input-cred {
  color: #565859;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  background: #f6f5f9;
  border-color: #f6f5f9;
  color: inherit;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  /* padding: 3px 20px;
  
  padding-left: 20px; */
  height: 49px;
}

.mobile_seperator {
    border: 0;
    border-bottom: solid 2px #d4d4d4;
    height: 40px;
    display: flex;
    align-items: center;
    color: gray;
    background: none;
}

.regester_editing_field {
  border: 0;
  border-bottom: solid 2px #d4d4d4;
  height: 40px;
  display: flex;
  padding: 0px 10px;
  font-weight: normal;
  width: 100%;
  color: #686868;
  background: none;
  outline: none;
}

.regester_editing_field:focus {
    /*background-color: #fffdf0;*/
    border: 0;
    border-bottom: solid 3px rgb(0 148 211 / 40%) !important;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    width: 100%;
    /*border-radius: 6px !important;*/
    font-weight: normal;
    outline: none;
}

.login-icon {
  left: 28px;
  top: 25px;
  z-index: 2;
  font-size: 15px;
  position: absolute;
}

.login-input {
  color: #565859;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  background: #f6f5f9;
  border-color: #f6f5f9;
  color: inherit;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 20px;
  padding-left: 45px;
  height: 56px;
}
.login-btn {
  font-size: 15px;
  font-weight: 500;
  border-style: solid;
  border-radius: 5px;
  border-color: transparent;
  padding: 0 36px;
  text-align: center;
  color: #ffffff;
  background-image: -webkit-linear-gradient(
    232deg,
    #ef5323 0,
    #fc6a42 50%,
    #ef5323 100%
  );
  background-image: -o-linear-gradient(
    232deg,
    #ef5323 0,
    #fc6a42 50%,
    #ef5323 100%
  );
  background-image: linear-gradient(
    218deg,
    #ef5323 0,
    #fc6a42 50%,
    #ef5323 100%
  );
  background-size: 200% auto;
  background-position: left center;
  border-width: 1px;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);

  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}
.login-btn:hover,
.login-btn:active,
.login-btn:focus {
  color: #ffffff;
  background-image: -webkit-linear-gradient(
    232deg,
    #ef5323 0,
    #fc6a42 50%,
    #ef5323 100%
  );
  background-image: -o-linear-gradient(
    232deg,
    #ef5323 0,
    #fc6a42 50%,
    #ef5323 100%
  );
  background-image: linear-gradient(
    218deg,
    #ef5323 0,
    #fc6a42 50%,
    #ef5323 100%
  );
  background-position: right center;
  -webkit-transform: translateY(-3px);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
}
.login-text {
  font-size: 1.5rem;
  color: #565859;
  font-family: Rubik, Helvetica, Arial, sans-serif;
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #565859;
  font-family: Rubik, Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.23;
}
.login-msg-text {
  font: 1.2rem;
}
.login-msg-text a {
  color: #e24f27;
  text-decoration: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.login-msg-text a:hover {
  text-decoration: underline;
}

.css-1s2u09g-control {
  border: none !important;
}

/* .css-1s2u09g-control:hover {
  border: none !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
} */
/* .css-1pahdxg-control:hover {
  border: none !important;
} */

.seachbox-main-container {
  display: flex;
  border-bottom: solid 2px #d4d4d4;
  width: 100%;
}

.login-search-input-container {
  display: flex;
  align-items: center;
  padding: 5px;
  /* background-color: #fff;
  border-bottom: 1px solid #ccc; */
  /* border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  width: 100%;
}

.select-dailcode {
  padding: 10px;
  font-size: 16px;
  outline: none;
  border: none;
  font-size: 16px;
  outline: none;
  background: #fff;
}

.phoneno-input-container {
  padding: 10px;
  border: none;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.phoneno-input-container:focus {
  border-color: transparent;
}

#search-button {
  padding: 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

#search-button img {
  width: 16px;
  height: 16px;
}
