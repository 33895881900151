.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 5%; /* Add some padding to create space from the top */
  background-color: #f0f0f0;
}

.spacialMessage {
  font-size: 25px;
  font-weight: 500;
  padding: 5px;
  padding-top: 2%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 500px;
  padding: 2px 10px;
  align-items: center;
}

.brand {
  font-size: 1.2rem;
  font-weight: 700;
  color: #007bff;
}

.backToMain {
  font-size: 0.9rem;
  color: blue;
  text-decoration: underline;
  font-weight: 700;
}

.backToMain:hover {
  text-decoration: underline;
}

.form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
  text-align: center;
  /* Remove fixed height */
}

.inputGroup {
  margin-bottom: 1.5rem;
  text-align: left;
}

.inputGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #333;
  font-weight: 700;
}

.inputGroup input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.forgotPassword {
  margin-bottom: 1.5rem;
  text-align: right;
}

.forgotPassword a {
  color: blue;
  text-decoration: none;
  font-size: 0.9rem;
}

.forgotPassword a:hover {
  text-decoration: underline;
}

.signInButton {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signInButton:hover {
  background-color: #0056b3;
}

.createAccount {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #6a6969;
  font-weight: 600;
}

.createAccount a {
  color: blue;
  text-decoration: none;
}

.createAccount a:hover {
  text-decoration: underline;
}
.errorMessage {
  color: red;
  margin-bottom: 1rem;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .form {
    width: 300px; /* Adjust width for mobile */
    padding: 1.5rem;
  }

  .header {
    max-width: 300px; /* Adjust the header to align with the form */
  }
}
