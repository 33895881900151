/* CheckOut.module.css */

.checkoutContainer {
  padding: 16px;
}

.title {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;
}

.checkoutItems {
  margin-bottom: 16px;
}

.checkoutItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.itemName {
  font-weight: bold;
}

.itemType {
  color: #555;
}

.purchaseButton {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.purchaseButton:hover {
  background-color: #0056b3;
}

/* CheckOut.module.css */

.deleteIcon {
  cursor: pointer;
  color: #dc3545; /* Red color for the delete icon */
  transition: color 0.3s;
}

.deleteIcon:hover {
  color: #c82333; /* Darker red color on hover */
}

.branchName {
  color: #555;
  font-size: 14px;
  margin-top: 4px;
}
