.image-container {
  height: 500px;
}

.container-cropper {
  height: 90%;
  padding: 10px;
}

.cropper {
  height: 90%;
  position: relative;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 60%;
}

.container-buttons {
  border: 1px solid #f5f5f5;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-model {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.model-content {
  border-radius: 4px;
  width: 600px;
}

.video-close {
  float: right;
  padding: 5px;
  font-size: 20px;
  color: red;
  margin-left: -20px;
  cursor: pointer;
}

.MuiSlider-thumb {
  color: #3f51b5;
  animation-name: backgroundThumb;
  animation-duration: 2s;
}

@keyframes backgroundThumb {
  0% {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
  25% {
    box-shadow: 0px 0px 0px 8px rgba(60, 179, 113, 0.16);
  }
  50% {
    box-shadow: 0px 0px 0px 8px rgba(255, 165, 0, 0.16);
  }
  100% {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
}
/* .MuiSlider-thumbColorPrimary {
  animation-name: leftAnimate;
  animation-duration: 2s;
}

@keyframes leftAnimate {
  0% {
    left: 4%;
  }
  25% {
    left: 8%;
  }
  50% {
    left: 12%;
  }
  100% {
    left: 0%;
  }
} */

.MuiSlider-root {
  animation-name: sliderAnimate;
  animation-duration: 2s;
}
@keyframes sliderAnimate {
  0% {
    left: 0px;
    top: 0px;
  }
  25% {
    left: 100px;
    top: 0px;
  }
  50% {
    left: 0px;
    top: 0px;
  }
}

/* .MuiSlider-track {
  width: 0%;
  animation-name: widthAnimate;
  animation-duration: 2s;
}

@keyframes widthAnimate {
  0% {
    width: 4%;
  }
  25% {
    width: 8%;
  }
  50% {
    width: 12%;
  }
  100% {
    width: 0%;
  }
} */

video {
  height: 337px;
}
