.button_bar {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress_box {
  border: solid 2px #c4c4c4 !important;
  border-radius: 10px;
  margin-bottom: 12px;
}

.progress_header {
  padding: 8px !important;
}

.progress_profile_image {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
}

.system_user_profile_image {
  width: 34px;
  height: 34px;
  border-radius: 50% !important;
}

.commonSidebar {
  width: 25% !important;
}

.w-zero {
  width: 0;
}

/*new tree structure*/

.node_line {
  display: flex;
  align-items: center;
  height: 22px;
}

.node_line :hover {
  background-color: #f3f4f6 !important;
}

.icon_tree_area {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prevelages_header {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.node_name_area {
  padding: 0px 4px;
}

.node_buttoms_area {
  height: 22px;
  display: flex;
  align-items: center;
  margin: 0px 8px;
  display: none;
}

.node_line:hover .node_buttoms_area {
  display: block;
}

.node_buttoms {
  margin: 0px 8px;
}

/*loading image*/
.loader-spiner {
  width: 18px;
  height: 18px;
  border: 3px solid #fff;
  border-bottom-color: #1aa9da;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------*/

/*old tree structure*/

.block_section {
  padding-left: 20px;
  margin-left: 12px !important;
  margin-bottom: 4px;
}

.block_border {
  border-left: solid 1px bisque;
}

.value_section {
  min-height: 25px;
  padding: 4px 4px;
}

/*--------------------------*/

/*tables strucure and list----------------------------------------------------t*/

.row_area :hover {
  background-color: #f3f4f6 !important;
}

.flip_display_area {
  display: none;
}

.row_area:hover .flip_display_area {
  display: block;
}

.table_line_div {
  display: flex;
  align-items: center;
  min-height: 30px;
  color: #686868;
}

.table_line_part_div {
  /*align-items: center;*/
  height: 30px;
  color: #686868;
}

.table_header_div {
  display: flex;
  align-items: center;
  height: 30px;
  border-bottom: solid 1px #ccc;
  font-weight: 600;
}

.top_border_div {
  border-top: solid 1px #ccc;
  /*border-bottom: solid 1px #dddddd;*/
}

.bottom_border_div {
  border-bottom: solid 1px #ccc;
}

.table_header_background {
  background-color: ButtonFace;
}

/*message fields------------------------------------t*/
.message_field {
  border-style: solid;
  border: solid 1px gray;
  /*padding: 8px;*/
  font-weight: normal;
  color: #686868;
  border-radius: 6px;
  background: none;
  outline: none;

  display: flex;
  align-items: center;
  height: 30px;
}

.message_field:focus {
  border: solid 2px rgb(0 148 211 / 40%) !important;
  padding: 5px;
}

/*forms and fields------------------------------------t*/

.accounts_type_option {
  border: none;
  line-height: 20px;
  padding: 4px;
  font-weight: normal;
  width: auto;
  color: #686868;
  background: none;
  outline: none;
}

.data_line {
  /*margin-bottom: 3px;*/
  display: flex;
  align-items: center;
  height: 30px;
}

.label_area {
  border-style: solid;
  line-height: 20px;
  padding: 4px;
  border-width: 0px 0px 2px 0px !important;
  border-color: transparent !important;
  width: 150px;
  font-weight: 600;
}

.viewing_field {
  border-style: solid;
  border-width: 0px 0px 1px 0px !important;
  line-height: 20px;
  padding: 4px;
  border-color: lightgray;
  font-weight: normal;
  width: 100%;
  color: #686868;
}

.textarea_editing_field {
  border: solid 1px gray !important;
  padding: 4px;
  font-weight: normal;
  width: 100%;
  color: #686868;
  background: none;
  outline: none;
}

.error_field {
  border-style: solid;
  border-width: 0px 0px 2px 0px !important;
  line-height: 20px;
  padding: 4px;
  border-color: red;
  font-weight: normal;
  width: 100%;
  color: #686868;
  background: none;
  outline: none;
}

.editing_field {
  border-style: solid;
  border-width: 0px 0px 1px 0px !important;
  line-height: 20px;
  padding: 4px;
  border-color: gray;
  font-weight: normal;
  width: 100%;
  color: #686868;
  background: none;
  outline: none;
}

.editing_field:focus {
  background-color: #fffdf0;
  border: solid 2px rgb(0 148 211 / 40%) !important;
  padding: 2px 2px 3px 2px;
  line-height: 20px;
  width: 100%;
  border-radius: 6px !important;
  font-weight: normal;
  outline: none;
}

.textarea_editing_field:focus {
  background-color: #fffdf0;
  border: solid 2px rgb(0 148 211 / 40%) !important;
  padding: 4px;
  line-height: 20px;
  width: 100%;
  border-radius: 6px !important;
  font-weight: normal;
  outline: none;
}

.title_area {
  line-height: 20px;
  padding: 4px;
  border-bottom: solid 2px gray;
  font-weight: 600;
  min-width: 50% !important;
  margin-bottom: 8px;
}

/*Users list-------------*/

.user_line_div {
  display: flex;
  align-items: center;
  height: 30px;
  color: #686868;
}

.user_line :hover {
  background-color: #f3f4f6 !important;
}

.user_line:hover .flip_display_area {
  display: block;
}

.user_line_area {
  padding: 7px;
}

.space-betw-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user_profile_image {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}
/*--------------------------------------------------*/

.unchecked_checkbox {
  border: solid 1px gray;
  height: 12px;
  width: 12px;
  background-color: ButtonFace;
  border-radius: 2px;
}

.buttoms_bar_height {
  height: 36px;
}

attachment_icon {
  height: 5px;
}

.row1 {
  margin: 0px 10px;
  display: none;
}

.file-wrapper {
  display: block;
}

.file-wrapper:hover {
  background-color: ButtonFace;
}

.file-wrapper:hover .row1 {
  display: block;
}

.flag_icon {
  height: 14px;
}

.circular-progress {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.circular-progress:before {
  content: "";
  position: absolute;
  height: 84%;
  width: 84%;
  background-color: #ffffff;
  border-radius: 50%;
}

.value-container {
  color: #61605f;
  z-index: 1;
}

.hlp_tap_aria {
  margin-top: 10px;
  border-bottom: solid 2px lightgray;
  line-height: 20px;
  padding: 4px;
  font-weight: bold;
}

.section_details_aria {
  margin-top: 10px;
  border-bottom: solid 2px lightgray;
  line-height: 20px;
  padding: 4px;
  font-weight: 500;
}

.tap_menu {
  display: flex;
  border-bottom: solid 1px #ccc;
  height: 40px;
  align-items: center;
}

.tap_head_area {
  padding: 0px 14px;
  height: 40px;
  align-items: center;
  display: flex;
}

.active_tap_head_area {
  border-bottom: 4px solid #0178d4;
  font-weight: bold;
}

.profilemenu-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  white-space: nowrap;
}

.profilemenulink {
  white-space: nowrap;
}

.profilemenu-scroll::-webkit-scrollbar {
  width: 0px;
}

.profilemenu-scroll::-webkit-scrollbar-track {
  background: none;
}

.profilemenu-scroll::-webkit-scrollbar-thumb {
  background: none;
}

.profilemenu-scroll::-webkit-scrollbar-thumb:hover {
  background: none;
}

.employee_image {
  width: 120px;
  border-radius: 50% !important;
  /* margin-bottom:3px;*/
}

.coversation_header_area {
  border-bottom: solid 1px grey;
  margin: 0px 0px 20px 0px;
  width: 75%;
}

.chatting_area {
  margin: 30px 30px 0px 30px;
  padding: 0px 4px 10px 4px;
  width: 55%;
}

.chatting_owner {
  float: right;
}

other_part_chatting {
  float: left;
}

.chatting_profile_image {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}

.remark_area {
  border-bottom: solid 1px grey;
  margin: 0px 30px 30px 30px;
  padding: 0px 4px 10px 4px;
}

.remark_profile_image {
  width: 46px;
  height: 46px;
  border-radius: 50% !important;
}

.profile_image {
  width: 28px;
  height: 28px;
  border-radius: 50% !important;
}

.decision_items_line {
  border-style: solid;
  line-height: 20px;
  padding: 4px;
  border-width: 0px 0px 2px 0px !important;
  border-color: lightgray !important;
  width: 100%;
  font-weight: 500;
}

.tale_like_header {
  line-height: 16px;
  padding: 4px;
  border-color: lightgray;
  font-weight: 600;
}

.tale_like_data_line {
  border-style: solid;
  line-height: 20px;
  padding: 4px;
  border-width: 1px 0px 0px 0px !important;
  border-color: lightgray;
  font-weight: normal;
  line-height: 24px;
}

.tale_like_data_line :hover {
  background-color: #e5e6e7;
}

.text_area_edit {
  border: solid 2px #e5e6e7;
  line-height: 20px;
  padding: 4px;
  margin: 4px;
  font-weight: normal;
  width: 100%;
  background: none;
  outline: none;
}

.text_area_edit:focus {
  background-color: #fffdf0;
  border: solid 2px rgb(0 148 211 / 40%) !important;
  padding: 2px 2px 3px 2px;
  line-height: 20px;
  width: 100%;
  border-radius: 6px !important;
  font-weight: normal;
  outline: none;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
}

.mony_alighn {
  text-align: right !important;
}

.center_alighn {
  text-align: center !important;
}

.menu_like {
  position: absolute;
  z-index: 100;
}

.branch_menu {
  background-color: whitesmoke;
  width: auto;
  margin: 14px 10px;
  padding: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  z-index: 500;
  position: absolute;
}

.dropdown_menu {
  background-color: white;
  width: auto;
  padding: 0px 5px 5px 5px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  z-index: 500;
  position: absolute;
}

.highlighted_background {
  background-color: #fffdf0;
}

.highlited_row {
  background-color: yellow;
}

.details_dropdown {
  width: 400px;
  position: absolute;
  z-index: 100;
  background-color: white;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  padding: 2px 6px;
}

.lable_field {
  border-style: solid;
  margin: 0;
  padding: 5px;
  border-width: 0px 0px 0px 0px;
  border-color: #e7ecf1;
  /*font-weight: normal;*/
  font-weight: 600;
  font-size: 14px;
}

.request_menu_item {
  padding: 8px;
}

.request_menu_line {
  margin-bottom: 0px;
}
.request_menu_line :hover {
  background-color: #e5e6e7;
  /*font-weight: 500;*/
}

.main_media_space {
  height: 300px;
  text-align: center;
}

.media_thumnails_div {
  height: 80px;
  margin: 14px 5px 0px 5px;
}

.media_image {
  height: 100%;
  /*border: solid 1px #ccc;*/
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
}

.table_header_div {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  height: 30px;
}

.hedden_area {
  display: none;
}

.buton_icon {
  height: 18px;
  margin: 0px 10px;
  vertical-align: middle;
  /*border: solid 1px red;*/
}

.buttons_div {
  /*  padding: 4px 0px 4px 0px;*/
  margin-bottom: 20px;
  /* align-items: center;*/
  /*height: 36px ;*/
  /*border: solid 1px gray;*/
}

.subbutton_div {
  font-size: 14px;
  margin-top: 6px;
  padding: 0px !important;
  /* border: solid 1px red;*/
}

.bottom_line {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px #ccc;
}

.system_menue_section_area {
  margin: 2px 25px;
  /* border-left: solid 1px gray; */
}

.header_menu_icon {
  border-bottom: solid 1px gray;
  margin: 0px 6px;
  padding: 0px 8px;
}

.underlined_Buton {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px #ccc;
  background-color: transparent;
  padding: 2px 12px 2px 12px;
  margin-right: 6px;
  margin-left: 6px;
  outline: none;
}

.table-verticle-middle TD {
  vertical-align: middle !important;
}

.table-frm {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  /*border-bottom: 1px solid #ced4da;*/
}

.table-frm th {
  border-style: solid;
  margin: 0;
  padding: 5px;
  border-width: 0px 0px 0px 0px;
  border-color: #e7ecf1;
  /*font-weight: normal;*/
  font-weight: 600;
}

.table-frm td {
  border-style: solid;
  margin: 0;
  padding: 5px;
  border-width: 0px 0px 0px 0px;
  border-color: #e7ecf1;
  text-align: right;
  height: 30px;
}

.table-frm td.line {
  padding: 2px;
}

.table-frm td.line div {
  border-style: solid;
  border-width: 0px 0px 1px 0px !important;
  border-color: #e5e6e7;
  padding: 3px;
}

.table-frm .view-field {
  padding: 3px 5px !important;
}

.table-frm-ed th,
.table-frm-ed td {
  padding: 3px !important;
}
.table-frm-ed2 th,
.table-frm-ed2 td {
  padding: 3px 1px !important;
}

.form-control {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

.input-sm {
  padding: 3px 5px;
  font-size: 13px;
}

.view_field_no_border {
  border-width: 0px;
  height: 28px !important;
  line-height: 1.5 !important;
}

.view_field {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ced4da;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  height: 28px !important;
  line-height: 1.5 !important;
}

.input-sm :focus {
  background-color: #fffdf0;
  border-color: #bcbec2;
  -webkit-box-shadow: rgb(0 148 211 / 40%) 0 0 10px;
  box-shadow: 0 0 10px rgb(0 148 211 / 40%);

  /*
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
    background-color: white;*/
}

.table-time td .input-sm {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.panel-body {
  padding: 0px;
}
.panel-body:before,
.panel-body:after {
  content: " ";
  display: table;
}

.panel-body:after {
  clear: both;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar,
.scroll {
  overflow: auto;
}

.mostly-customized-scrollbar::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #cecece; /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb {
  background: #aaa;
}

.hedden {
  display: none;
}

.progress_box {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  margin-bottom: 6px;
}

.progress_header {
  border-bottom: solid 1px #f5f5f5;
  padding: 6px 10px;
  margin: 0px !important;
  background-color: #f5f5f5;
}

.underlined_droplist {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px #ccc;
  width: 100%;
  background-color: transparent;
  height: 26px;
  /*margin-top: 5px;*/
  padding: 2px 6px 5px 6px;
  outline: none;
}

.progress {
  height: 0.25rem !important;
  width: 50px;
}

.progress-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.progress-color {
  color: #656966 !important;
}

.progress-bg-color {
  background: #979c98 !important;
}
/*.underlined_droplist:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid rgba(81, 203, 238, 1);
    padding: 2px 6px 5px 6px;
    background-color: transparent;
    height: 26px;
    outline: none;
}*/

.complete_border {
  border: solid 1px gray;
}

.header-tab-deatils-mobile {
  display: none;
}

.header-tab-deatils-desktop {
  display: flex;
}

@media (max-width: 768px) {
  .header-tab-deatils-mobile {
    display: flex;
  }

  .header-tab-deatils-desktop {
    display: none !important;
  }
}

.absolute_div {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #fffdf0;
  border: solid 1px lightgray;
  padding: 2px 2px 3px 2px;
  line-height: 20px;
  /*width: 100%;*/
  border-radius: 6px !important;
  font-weight: normal;
  outline: none;
  max-height: 200px;
  overflow: auto;
}

.dropdown_line {
  display: flex;
}

.dropdown_line :hover {
  background-color: lightgray;
}

.css-1s2u09g-control {
  border: none !important;
}

/* .css-1s2u09g-control:hover {
  border: none !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
} */
/* .css-1pahdxg-control:hover {
  border: none !important;
} */

.search-input-container {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.select-dailcode {
  padding: 10px;
  font-size: 16px;
  outline: none;
  border: none;
  font-size: 16px;
  outline: none;
  background: #fff;
}

.phoneno-input-container {
  padding: 10px;
  border: none;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.phoneno-input-container:focus {
  border-color: transparent;
}

#search-button {
  padding: 10px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

#search-button img {
  width: 16px;
  height: 16px;
}

@media print {
  @page {
    page: A4;
  }
  .print_section {
    padding: 5mm;
    width: 210mm;
    height: 297mm;
  }
}

@media only screen and (max-width: 767px) {
  /* Your mobile-specific styles here */
  .commonSidebar {
    width: 100% !important;
  }
  .search-input-container {
    width: 100% !important;
  }
}
