/* PYMstatus.module.css */

.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.success {
  color: green;
}

.failure {
  color: red;
}

.details {
  margin-top: 10px;
}

.detailItem {
  margin-bottom: 5px;
}

.message {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
