/* PaymentOptions.module.css */

.paymentWrapperBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paymentWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 500px;
}

.paymentOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  text-align: center;
}

.paymentOption img {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.paymentOptionName {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.serviceCharge {
  color: gray;
  font-size: 14px;
  margin-bottom: 10px;
}

.paymentOption input[type="radio"] {
  margin-top: 10px;
}
