
.input_field {
    outline: none;
    line-height: 24px;
    width: 100%;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    border: 1px solid rgba(81, 203, 238, 1);
    background-color: white;
}

.learn_line_div {
    line-height: 28px;
}

.learn_line {
    float: left;
    width: 85%;
}

.checked_area {
    float: left;
    width: 5%;
    text-align: center;
    line-height: 28px;
}